import { ErrorMsg, Header, SEO } from "@shared";
import { PageProps, graphql } from "gatsby";
import React, { useEffect } from "react";

import { BasicHero, Hero } from "@shared";
import GuideContent from "@components/guide/guideContent";
import MetguideForm from "@components/forms/metguideForm";
import { Query } from "@graphql-types";
import { sendMetguideViewItem } from "@util/datalayer";
import { usePageMeta } from "@util/logicHooks";
import { useStore } from "@state/store";

interface Props extends PageProps {
  data: Query;
}

export default function GuideTemplate({ data: { sanityForm, sanityHeader } }: Props) {
  const pagePaths = useStore(state => state.pagePaths);

  usePageMeta(sanityForm?.pageColour?.colour?.hex, sanityForm?._type);

  useEffect(() => {
    sendMetguideViewItem(slug?.current, title, sanityForm?.guideCategory?.[0]?.categoryId?.current);
  }, []);

  if (sanityForm == null) return <ErrorMsg data={sanityForm} msg="Error fetching data for page" />;

  const { slug, title, seo, description, guideInnerContent, hero } = sanityForm;

  console.log(hero);

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />

      {Boolean(hero?.backgroundImages && hero?.title) ? (
        <Hero data={hero} />
      ) : (
        <BasicHero
          heading={title}
          description={description}
          ctas={[{ linkText: "View all guides", internalLink: pagePaths?.guidesLanding }]}
        />
      )}
      <GuideContent data={guideInnerContent} marginOverride="50px auto" />
      <MetguideForm form={sanityForm} isInnerPage />
    </div>
  );
}

export const query = graphql`
  query formGuideQuery($slug: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityForm(slug: { current: { eq: $slug } }) {
      ...sanityForm
    }
  }
`;
