import React from "react";
import styled from "styled-components";

import { BlocksContent } from "@global";
import { Section, PageWidth } from "@util/standard";
import { Maybe, SanityBlockContent } from "@graphql-types";
import { mediaQuery } from "@util/constants";

interface Props {
  data: Maybe<SanityBlockContent> | undefined;
  marginOverride?: string;
}

export default function GuideContent({ data, marginOverride }: Props) {
  return (
    <Section aria-label="About guide" marginOverride={marginOverride}>
      <PageWidth>
        <ContentWrapper>
          <BlocksContent data={data} />
        </ContentWrapper>
      </PageWidth>
    </Section>
  );
}

const ContentWrapper = styled.div`
  /* OFFSETS TITLE SO THAT IT WRAPS P TEXT CORRECTLY */

  ${mediaQuery.mobileDown} {
    column-count: unset;
  }
`;
